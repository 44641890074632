<template>
<div class="login-container">
    <div class="col login-background"><!-- :style="{'background-image':'URL('+images.Background+')'}"-->
        <center style="margin: 120px;">
            <img :src="images.Logo" style="width: 100%;"/>
        </center>
        <!-- <div class="banner-text">
            <div class="banner-title">
                <str index="banner.title.events._text"/>
            </div>
            <div class="banner-subtitle">
                <str index="banner.subtitle.events._text"/>
            </div>
        </div>         -->
    </div>
    <div class="col">
        <Authenticator @authenticatorReady="$emit('authenticatorReady')" class="login-authenticator"  v-if="!$store.getters.signedIn"/>
        <!-- :options="{social:{Google:true, Facebook:true, LinkedIn:false, Twitter:false}}" -->
    </div>
</div>
</template>

<script>
import Footer from '@/components/Footer/Footer'
import Logo_en from '@/components/Authenticator/assets/logo.png'
import Logo_fr from '@/components/Authenticator/assets/logo_fr.png'
import Background from '@/components/Authenticator/assets/background.png'
import Authenticator from '@/components/Authenticator/Authenticator'
export default {
    name: "Login",
    components: {
        Authenticator,
        Footer
    },
    created: function(){
        if(this.signedIn){
            this.redirect()
        }
    },
    data: function(){
        return {}
    },
    methods: {
        redirect: function(){
            if(this.$router.history._startLocation!='/login'){
                this.$router.go(-1)
            }else if(this.$route.params.from && this.$route.params.from!='Login'){
                this.$router.push({name:this.$route.params.from})
            }else{
                this.$router.push({name:'Home'})
            }
        }
    },
    computed: {
        language: function(){
            return this.$store.getters.language
        },
        signedIn: function(){
            return this.$store.getters.signedIn
        },
        fullScreen: function(){
            return true
        },
        images: function(){
            return {
                Logo: this.language=='fr-ca' ? Logo_fr : Logo_en,
                Background
            }
        }
    },
    watch: {
        signedIn: function(){
            if(this.signedIn){            
                this.redirect()
            }
        }
    }
}
</script>

<style scoped lang="scss">
.login-container{
    padding: 0px;
    width: 100%;
    display: flex;
    justify-self: stretch;
    justify-content: space-evenly;
}

.col{
    padding: 0px;
    align-self: stretch;
    width: 50%;
}

.login-background{
    background-size: contain;
    background-position-x: 100%;
    background-color: white;
}
.login-authenticator{
    max-width: 600px;
}


.banner-text{
    color: white;
    width: 60%;
    position: relative; 
    top: -25px; 
    margin: 0 auto;
    margin-top: 50px;
    text-align: left;
    line-height: 35px;
}

    .banner-title{
        font-weight: bold;
        font-size: 30pt;
        margin: 0px 0px 15px 0px;
    }

    .banner-subtitle{
        font-weight: bold;
        line-height: 20px;
    }

@media only screen and (max-width: 1024px){
    .col{
        width: 100%;
    }
    .col:nth-child(1){
        display: none;
    }
    // .login-authenticator{
    //     margin: 0 auto;
    // }
}
</style>